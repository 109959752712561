<template>
  <!-- Success page-->
  <div class="misc-wrapper" style="align-items: flex-start">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="d-flex" style="min-height: 100px">
            <Lottie 
                v-if="showAnime"
                :options="animationData" 
                :height="100" 
                :width="200" 
            />
        </div>
        <h2 class="mb-1" style="font-size: 36px">
            Оплачено!
        </h2>
        <p class="mb-2" style="font-size: 20px">
            Контракт успешно оплачен
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
          style="font-size: 18px"
        >
          На главную
        </b-button>

      </div>
    </div>
  </div>
<!-- / Success page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import * as SuccessAnimeView from "@/assets/anime/success.json"


export default {
  name: 'PaymentSuccess',
  created() {

  },
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
        animationData: { 
            animationData: SuccessAnimeView.default,
            loop: false
        }, 
        showAnime: false
    }
  },
  computed: {
    
  },
  mounted() {
       setTimeout(() => {
        this.showAnime = true
      }, 3000 );
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
